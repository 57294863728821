import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useCookies } from 'react-cookie';
import { Card } from '../components/CommonComponents';
import { useLocation } from "react-router-dom";
 
const Camera = ({ jobid }) => {
    console.log('cam', jobid)
    const [cookies] = useCookies(['jwtToken', 'email']);
    const location = useLocation(); // Get the current route
    const [videoStream, setVideoStream] = useState(null);
    const [isStreaming, setIsStreaming] = useState(false);
    const [status, setStatus] = useState('Not connected');
    const videoRef = useRef(null);
    const wsRef = useRef(null);
    const streamRef = useRef(null);
    const email = cookies.email;
    const sessionId = useRef(null);
 
    useEffect(() => {
        startCamera();
 
        if (jobid) {
            startStream();
        } else {
            stopStream();
        }
 
        if (location.pathname === '/discussion-forum') {
            stopCamera();
            stopStream();
        }
 
        // Cleanup function to stop camera when component unmounts or on route change
        return () => {
            stopCamera();
            stopStream();
        };
    }, [jobid]);
 
 
    const startCamera = async (delay = 1000) => {
        console.log('Starting camera with delay of', delay, 'ms');
        setTimeout(async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true });
                setVideoStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (error) {
                console.error('Error accessing the camera:', error);
            }
        }, delay);
    };
   
    const stopCamera = async () => {
        console.log('Attempting to stop camera...');
 
        // Check if the video element has a stream attached
        if (videoRef.current && videoRef.current.srcObject) {
            const stream = videoRef.current.srcObject;
            const tracks = stream.getTracks();
 
            // Stop all tracks (video and audio)
            tracks.forEach(track => {
                console.log('Stopping track:', track);
                track.stop();
            });
 
            // Clear the video element's source object
            videoRef.current.srcObject = null;
            setVideoStream(null); // Clear the video stream state
        }
 
        // If there's still a video stream, stop its tracks
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
            setVideoStream(null);
        }
 
        // Optionally try to request media devices with no video to ensure camera stops
        try {
            await navigator.mediaDevices.getUserMedia({ video: false });
            console.log('Camera should be stopped now.');
        } catch (err) {
            console.error('Error stopping the camera with getUserMedia:', err);
        }
    };
 
 
    const startStream = () => {
        if (!email) {
            console.error('Email is required for streaming');
            return;
        }
        console.log('Starting stream with email:', email);
   
        wsRef.current = new WebSocket('wss://hiring-assistant.krtrimaiq.ai/open-cv-stream/face-recognition');
   
        wsRef.current.onopen = () => {
            console.log('WebSocket connected');
            setStatus('Connected');
            wsRef.current.send(JSON.stringify({ type: 'start', email, job_id: jobid }));
            setIsStreaming(true);
   
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(mediaStream => {
                    streamRef.current = mediaStream;
                    videoRef.current.srcObject = mediaStream;
                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current.play();
   
                        // Delay sending frames
                        setTimeout(() => {
                            sendFrames();
                        }, 1000); // Delay in milliseconds (e.g., 3000 ms = 3 seconds)
                    };
                })
                .catch(err => {
                    console.error("Error accessing the camera:", err);
                });
        };
   
        wsRef.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.type === 'session_start') {
                sessionId.current = data.session_id;
                console.log('Session started:', data.session_id);
            } else if (data.type === 'face_status') {
                updateStatus(data.data);
                // Handle face status updates here if needed
            } else if (data.type === 'error') {
                alert(data.message);
                stopStream();
            }
        };
   
        wsRef.current.onclose = () => {
            console.log('WebSocket disconnected');
            setStatus('Disconnected');
            setIsStreaming(false);
        };
    };
   
 
    const stopStream = () => {
        if (wsRef.current) {
            wsRef.current.send(JSON.stringify({ type: 'stop', sessionId: sessionId.current }));
            wsRef.current.close();
        }
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
        }
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
        setIsStreaming(false);
    };
 
    const sendFrames = () => {
        if (videoRef.current && wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            canvas.getContext('2d').drawImage(videoRef.current, 0, 0);
            const imageData = canvas.toDataURL('image/jpeg');
            wsRef.current.send(JSON.stringify({ type: 'frame', data: imageData, sessionId: sessionId.current }));
        }
        if (streamRef.current && streamRef.current.active) {
            setTimeout(sendFrames, 1000 / 30); // 30 fps
        }
    };
 
    const updateStatus = (faceStatus) => {
        let statusText = '';
 
        // Check if the face is centered and update the status accordingly
        if (faceStatus.is_centered) {
            statusText += '';
        } else {
            statusText += 'Align yourself to the center.\n';
        }
 
        // statusText += `Centered: ${faceStatus.is_centered}\n`;
        // statusText += `Eyes Visible: ${faceStatus.eyes_visible}\n`;
        if (faceStatus.proper_lighting) {
            statusText += '';
        } else {
            statusText += 'Proper lighting is required\n';
        }
        // statusText += `Proper Lighting: ${faceStatus.proper_lighting}\n`;
        // statusText += `Face Turning Out: ${faceStatus.face_turning_out}\n`;
        // statusText += `Recognized Names: ${faceStatus.recognized_names.join(', ')}`;
 
        setStatus(statusText);
    };
 
    return (
        <>
            <div>
                <>
                    <div className="w-64 h-44 top-[8.5rem] right-16 absolute flex items-center justify-center">
                        <video
                            ref={videoRef}
                            className="rounded-lg w-full h-full object-cover"
                            autoPlay
                            playsInline
                            muted
                        />
                    </div>
                    {status && (
                        <div className="w-64 h-32 top-72 right-16 absolute flex items-center justify-center">
                            <Card className="relative flex top-1 left-1/2 transform -translate-x-1/2 bg-white rounded-md mt-2 p-4">
                                {status}
                            </Card>
                        </div>
                    )}
                </>
            </div>
        </>
    )
 
};
 
export default Camera;