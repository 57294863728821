import React, { useEffect, useState, useRef } from 'react';
import SpeakeImage from "../assets/speakerImg.svg";
import { faVolumeMute, faVolumeUp, faMicrophone, faComments } from '@fortawesome/free-solid-svg-icons';
import { medbotVoices } from "../components/Mapping";
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const DiscussionForum = () => {
    const [messageText, setMessageText] = useState('');
    const [initialMessageSpoken, setInitialMessageSpoken] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [cookies] = useCookies(['voice', 'jobId', 'email']);
    const [isMuted, setIsMuted] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [responseHtml, setResponseHtml] = useState('');
    const audioRef = useRef(null);
    const recognition = useRef(null);
    const ws = useRef(null);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const handlePopState = (event) => {
          event.preventDefault();
          alert('The interview is completed. You cannot go back.');
    
          // Push the current state again to prevent going back
          window.history.pushState(null, '', window.location.href);
        };
    
        window.addEventListener('popstate', handlePopState);
    
        // Push the initial state to ensure the back button doesn't navigate away
        window.history.pushState(null, '', window.location.href);
    
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, []);
    
    
    
    
    useEffect(() => {
        if (window.SpeechRecognition || window.webkitSpeechRecognition) {
            recognition.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
            recognition.current.lang = 'en-US';
            recognition.current.interimResults = false;
            recognition.current.maxAlternatives = 1;

            recognition.current.onresult = (event) => {
                if (event.results && event.results[0] && event.results[0][0]) {
                    const speechToText = event.results[0][0].transcript;
                    console.log('Recognized Speech:', speechToText);
                    setInputValue(speechToText);
                    sendMessage(speechToText);
                }
            };

            recognition.current.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
            };

            recognition.current.onend = () => {
                console.log('Speech recognition ended');
            };
        } else {
            console.warn('Speech Recognition API not supported.');
        }

        const message = "Thank you for participating in the interview! Your interview has been recorded and you will be notified soon. You can ask any questions here regarding the interview.";
        setMessageText(message);
        if (!initialMessageSpoken) {
            speak(message);
            setInitialMessageSpoken(true);
        }

        if (inputValue.trim() !== '') {
            ws.current = new WebSocket('wss://hiring-assistant.krtrimaiq.ai/temporary-test-chat?message=' + inputValue + '&sender=sam');
            ws.current.onopen = () => {
                console.log('WebSocket connection established');
            };
    
            const TestArray = [];
            ws.current.onmessage = (event) => {
                console.log('WebSocket message received:', event.data);
                const response = JSON.parse(event.data);
                const receivedText = response[0]?.text;
                TestArray.push(receivedText);
                setResponseHtml(receivedText); // Store the received HTML
            };
    
            ws.current.onerror = (error) => {
                console.error('WebSocket error:', error);
            };
    
            ws.current.onclose = () => {
                console.log('WebSocket connection closed', TestArray);
                const lastResponseHtml = TestArray[TestArray.length - 2];
    
                // Function to strip HTML tags
                const stripHtml = (html) => {
                    const tmp = document.createElement("DIV");
                    tmp.innerHTML = html;
                    return tmp.textContent || tmp.innerText || "";
                };
    
                const plainText = stripHtml(lastResponseHtml); // Get plain text from HTML
                setResponseHtml(lastResponseHtml); // Store the received HTML
                speak(plainText); // Pass plain text to speak function
            };
        }
    
        return () => {
            if (ws.current) {
                ws.current.close();
            }
        };
    
    }, [inputValue, initialMessageSpoken]);

    const speak = async (text) => {
        const audio = audioRef.current;
        let voice = cookies.voice || medbotVoices[0].value;
        const body = JSON.stringify({ voice: voice, text: text });

        try {
            const response = await fetch('https://dev.genai.krtrimaiq.ai:8000/text_to_speech', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const mediaSource = new MediaSource();
            audio.src = URL.createObjectURL(mediaSource);

            mediaSource.addEventListener('sourceopen', async () => {
                const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
                const reader = response.body.getReader();

                const pump = async () => {
                    try {
                        const { done, value } = await reader.read();
                        if (done) {
                            mediaSource.endOfStream();
                            audio.play();
                            audio.onended = () => {
                                setShowButton(true);
                            };
                            return;
                        }
                        sourceBuffer.addEventListener('updateend', () => {
                            if (!sourceBuffer.updating) {
                                pump();
                            }
                        });
                        sourceBuffer.appendBuffer(value);
                    } catch (error) {
                        console.error('Error reading stream:', error);
                    }
                };

                await pump();
            });

            audio.oncanplaythrough = () => {
                audio.play();
            };
        } catch (error) {
            console.error('Error fetching or playing audio:', error);
        }
    };

    const sendMessage = (text) => {
        if (text.trim() !== '') {
            const sender = cookies.email || 'anonymous';
            const message = JSON.stringify({
                message: text,
                sender: sender,
            });
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                ws.current.send(message);
                console.log('WebSocket message sent:', message);
            } else {
                console.warn('WebSocket is not open. Ready state:', ws.current ? ws.current.readyState : 'WebSocket is null');
            }
        }
    };

    const toggleMute = () => {
        const audio = audioRef.current;
        audio.muted = !audio.muted;
        setIsMuted(!isMuted);
    };

    const handleChatNavigation = () => {
        navigate('/chat'); // Use navigate to redirect to chat page
        setIsMuted(false);
    };

    return (
        <div className="flex flex-col">
            <div className="flex-grow p-4">
                <div className="text-[#224B99] text-center font-semibold text-3xl">
                    Interview Completed
                </div>
                <div className="flex items-center justify-center mt-4">
                    <div className="bg-white p-4 rounded-lg shadow-lg w-full max-w-2xl">
                        <div className="text-[#224B99] text-center font-bold text-sm mb-2">KRTRI</div>
                        <img
                            src={SpeakeImage}
                            alt="Interview completed"
                            className="w-full h-auto"
                        />
                        <div className="bg-[#F7F9FD] my-2">
                            <div className="flex text-[#224B99] opacity-100 font-semibold">
                                <div dangerouslySetInnerHTML={{ __html: responseHtml || messageText }} />
                            </div>
                        </div>
                        <hr className="my-4 border-[#224B99] opacity-25 border-[1px]" />
                        <div className="text-[#2E2E2E] text-center font-bold text-sm my-2 opacity-75">YOU</div>
                        <input
                            type="text"
                            value={inputValue}
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="You can ask question using microphone or chat with us click on message"
                            readOnly
                        />
                        <div className="flex justify-center mt-4 items-center">
                            <button
                                onClick={toggleMute}
                                className="rounded-full text-[#6985B9] p-3 shadow-lg mx-2"
                            >
                                <FontAwesomeIcon icon={isMuted ? faVolumeMute : faVolumeUp} size="lg" />
                            </button>
                            <div className='bg-[#E9EDF5] rounded-full p-3'>
                                <button
                                    onClick={() => recognition.current.start()}
                                    className="rounded-full bg-white text-[#4366A8] p-3 shadow-lg mx-2"
                                >
                                    <FontAwesomeIcon icon={faMicrophone} size="lg" />
                                </button>
                            </div>
                            <button
                                onClick={handleChatNavigation} // Call handleChatNavigation on click
                                className="rounded-full text-[#6985B9] p-3 shadow-lg mx-2"
                            >
                                <FontAwesomeIcon icon={faComments} size="lg" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <audio ref={audioRef} style={{ display: 'none' }} />
        </div>
    );
};

export default DiscussionForum;
